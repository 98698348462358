// src/components/ComingSoon.js
import React from 'react';
import { Box, Button, Center, Heading, Text, VStack, Flex, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Input, FormControl, FormLabel, Hide } from '@chakra-ui/react';
import backgroundImage from './background.png'; // Import your background PNG image
import logoImage from './logo.png'; // Import your logo PNG image

const ComingSoon = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      position="relative"
      h="100vh"
      overflow="hidden"
      bg="#EEECE9" // Background color
    >
      <Box
        position="absolute"
        bgImage={`url(${backgroundImage})`} // Full-page background image
        bgSize="cover"
        bgPosition="center"
        bgRepeat="no-repeat"
        opacity={0.5}
        w="100%"
        h="70%"
        bottom={0}
        zIndex={0}
      />
      {/* Main Content */}
      <Center>
        <Flex
          direction={{ base: 'column', md: 'row' }} // Stacks on mobile, side by side on larger screens
          mt={16}
          alignItems="center"
          justifyContent="space-between"
          maxW="1200px"
          w="100%"
          px={4}
        >
          {/* Left Column */}
          <VStack spacing={8} alignItems="center" flex="1" textAlign={{ base: 'center', md: 'left' }}>
            {/* Logo PNG */}
            <img src={logoImage} alt="Logo" style={{ width: '400px', height: 'auto' }} />
          </VStack>

          {/* Right Column */}
          <Hide below='md'>
            <VStack spacing={8} alignItems="center" flex="1" textAlign={{ base: 'center', md: 'left' }}>
              <Heading as="h2" size={{ base: '1xl', md: '2xl' }} color="black" fontWeight={300}>
                Why have ordinary when you can have extraordinary
              </Heading>
              <Text fontSize="md" color="gray.600" maxW="600px" fontWeight={500}>
                REFLECT YOUR PASSION & CREATE YOUR OWN DRAMATIC BACKDROP WITH PERSONALISED WALLPAPER.
              </Text>
            </VStack>
          </Hide>
        </Flex>
      </Center>

      <Box position="absolute" bottom={5} w="100%" zIndex={1} textAlign="center" p={4}>
        <Heading as="h3" size={{ base: 'xl', md: '2xl' }} mb={{ base: 16, md: 8 }} color="black">
          Watch out for our launch
        </Heading>
        <Button size="lg" onClick={onOpen} background="#C9407E" color="white" mb={{ base: 4, md: 0 }} _hover={{ bg: '#A22C61' }}>
          Sign Up Now
        </Button>
      </Box>


      {/* Mailchimp Signup Modal */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent p={6} borderRadius="md" boxShadow="xl" bg="#EEECE9">
          <ModalHeader textAlign="center" fontSize="2xl" color="black">
            Subscribe to our Newsletter
          </ModalHeader>
          <ModalCloseButton color={'black'} />
          <ModalBody>
            <form
              action="https://wallfandango.us13.list-manage.com/subscribe/post?u=93e41902a60bfcebfd05411fd&amp;id=a703564c1f&amp;f_id=00da21eaf0"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_blank"
              noValidate
            >
              <VStack spacing={4}>
                <FormControl id="mce-EMAIL" isRequired>
                  <FormLabel htmlFor="mce-EMAIL" color="black">
                    Email Address
                  </FormLabel>
                  <Input
                    type="email"
                    name="EMAIL"
                    required
                    borderColor="black"
                    focusBorderColor="black"
                    color={'black'}
                    borderWidth={1}
                  />
                </FormControl>

                <Button type="submit" background="#C9407E" color="white" w={{ base: '100%', md: '50%' }} _hover={{ bg: '#A22C61' }}>
                  Subscribe
                </Button>
              </VStack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ComingSoon;
